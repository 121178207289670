import React, { Props, Component, Fragment } from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Drawer from './Common/Drawer'
import SchedulePanel from '../Components/SchedulePanel';
import Images from '../Components/Images';
import DateAndTimePickers from '../Components/DateAndTimePickers';



import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import InputLabel from '@material-ui/core/InputLabel';

import TableHead from '@material-ui/core/TableHead';
import Icon from '@material-ui/core/Icon';
import Fab from '@material-ui/core/Fab';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import MenuItem from '@material-ui/core/MenuItem';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { compose } from 'redux';
import { connect } from 'react-redux';

import AppBar from './Common/MainAppBar';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import Label from '@material-ui/icons/Label';

import { validateUser, storeAuthToken, fetchUser, storeUser } from '../Actions/AuthActions';
import { Redirect } from 'react-router-dom'

import logo from '../Img/logo.png'
import Profile from '../Img/profile.png'
import Loader from './Common/Loader';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';

import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';

import Radio from '@material-ui/core/Radio';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Grid from '@material-ui/core/Grid';
import StepButton from '@material-ui/core/StepButton';
import { Divider } from '@material-ui/core';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import FormGroup from '@material-ui/core/FormGroup';

import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import ListSubheader from '@material-ui/core/ListSubheader';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';


import { fetchCategories } from '../Actions/CategoryAction';
import { fetchLocations } from '../Actions/LocationAction';
import { updateActivity, GetActivity, LockActiveActivity, uploadPicture, ReviewSubmit, RemoveImage, showInfoBox, FetchPolicies } from '../Actions/ActivityActions';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';



import './../activity/css/supplier-ui.css';
import { SnackBar } from '../Actions/CommonActions';

import TermsBpx from './../Components/TermsBox';
import OptionsBox from './../Components/OptionsBox';
import ImageBox from './../Components/ImageBox';
import ActivityStatus from './../Components/ActivityStatus';
import PaxDefindBox from './../Components/PaxDefineBox';

import Step1Icon from './../activity/images/aktv-step1.png';
import Step2Icon from './../activity/images/aktv-step2.png';
import Step3Icon from './../activity/images/aktv-step3.png';
import Step4Icon from './../activity/images/aktv-step4.png';
import Step5Icon from './../activity/images/aktv-step4.png';

const drawerWidth = 240;

const styles = theme => ({

  root: {
    width: '90%',
  },
  button: {
    marginRight: theme.spacing.unit
  },
  backButton: {
    marginRight: theme.spacing.unit,
  },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },


  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },

  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },

  fab: {
    margin: theme.spacing.unit,
  },

  extendedIcon: {
    marginRight: theme.spacing.unit,
  },

  wrap: {
    width: 480,
    margin: 'auto',
    marginTop: 100,

  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,

  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: '98%'
  },

  button: {
    margin: theme.spacing.unit,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },

  logoWrap: {
    textAlign: 'center',
  },
  toolbar: theme.mixins.toolbar,
  icon: {
    color: "rgba(0, 0, 0, 0.54) !important"
  }
});


const createCard = (title, value) => {
  return <Card >
    <CardContent>
      <Typography color="textSecondary" gutterBottom>
        {title}
      </Typography>
      <Typography variant="h2" style={{ textAlign: 'center' }} component="h2">
        {value}
      </Typography>

    </CardContent>
    <CardActions>

    </CardActions>
  </Card>;
}

const mapStateToProps = (state) => {
  return {
    loading: state.Common.loading,
    loggedIn: state.Auth.loggedIn,
    categories: state.Categories.categories,
    activeTour: state.Activities.activeTour,
    locations: state.Locations.locations,
    know_policies: state.Activities.knowPolicies,
    cancel_policies: state.Activities.cancelPolicies,
    show: state.Activities.show_help,
  }
}

const mapDispatchToProps = dispatch => ({

  fetchCategories: () => dispatch(fetchCategories()),
  updateActivity: (data) => dispatch(updateActivity(data)),
  GetActivity: (aid) => dispatch(GetActivity(aid)),
  LockActiveActivity: () => dispatch(LockActiveActivity()),
  uploadPicture: (data, fileName, type, aid) => dispatch(uploadPicture(data, fileName, type, aid)),
  fetchLocations: () => dispatch(fetchLocations()),
  SnackBar: (show, message, varient) => dispatch(SnackBar(show, message, varient)),
  ReviewSubmit: (data) => dispatch(ReviewSubmit(data)),
  RemoveImage: (actId, imgId) => dispatch(RemoveImage(actId, imgId)),
  showInfoBox: (data) => dispatch(showInfoBox(data)),
  FetchPolicies: (type) => dispatch(FetchPolicies(type))
});

class HorizontalNonLinearAlternativeLabelStepper extends React.Component {


  constructor(props) {
    super(props);

    const { aid } = this.props.match.params;

    this.state = {
      activeStep: 0,
      completed: new Set(),
      skipped: new Set(),

      basicEditMode: false,
      overviewEditMode: false,
      pricingDefineEditMode: false,
      pricingValidated: false,
      paxValidated: false,
      availabilityValidated: false,
      id: 0,
      title: "",
      full_seo_url: "",
      tour_type: 0,
      duration: 0,
      duration_value: 0,
      cats: [],
      categories: [],
      overview: "",
      adults_age_from: null,
      adults_age_to: null,
      children_age_from: null,
      children_age_to: null,
      infant_age_from: null,
      infant_age_to: null,
      inclusion: [],
      exclision: [],
      know: [],
      condtions: [],
      contact_number: null,
      categories: this.GetFormattedCategories(),
      images: new Array(),
      itinery: new Array(),
      pricing_for: null,
      schedules: new Array(),
      locationgroups: [],
      locationgroupsLKR: [],
      sale_type: null,
      cutoff_time_duration: 0,
      cutoff_time_measure: 0,
      cutoff_time_related: 0,
      commission: 0,
      include_adults: 0,
      include_children: 0,
      include_infants: 0,
      cover_image: "",
      updated: false,
      catsupdated: false,
      know_policy: "",
      cancel_policy: "",
      cancel_custom: "",
      terms_accepted: 0,
      status: 0,
      local_inclustions: [],
      local_conditions: "",
      destination_location: 0,
      show_help: false,
      image_type: ""
    };


    this.handleCatChange = this.handleCatChange.bind(this);

    this.props.fetchCategories();
    this.props.fetchLocations();
    this.props.showInfoBox();
    this.props.FetchPolicies('knowPolicies');
    this.props.FetchPolicies('cancelPolicies');

    if (aid != undefined) {
      this.props.GetActivity(aid);
    }

  }





  GetFormattedCategories = () => {

    var $cats = [];
    for (var x = 0; this.props.categories.length < x; x++) {
      $cats.push({ id: '0', checked: false, label: 'Abc' });
    }

    return $cats;
  }



  static getDerivedStateFromProps(props, state) {



    if (props.activeTour.updated) {

      props.LockActiveActivity();
      return {
        id: props.activeTour.id,
        title: props.activeTour.title,
        full_seo_url: props.activeTour.full_seo_url,
        tour_type: props.activeTour.tour_type,
        duration: props.activeTour.duration,
        duration_value: props.activeTour.duration_value,
        categories: props.activeTour.categories,
        overview: props.activeTour.overview === null ? "" : props.activeTour.overview,
        adults_age_from: props.activeTour.adults_age_from,
        adults_age_to: props.activeTour.adults_age_to,
        children_age_from: props.activeTour.children_age_from,
        children_age_to: props.activeTour.children_age_to,
        infant_age_from: props.activeTour.infant_age_from,
        infant_age_to: props.activeTour.infant_age_to,
        inclusion: props.activeTour.inclusion,
        exclision: props.activeTour.exclision,
        know: props.activeTour.know,
        condtions: props.activeTour.condtions,
        contact_number: props.activeTour.contact_number,
        pricing_for: props.activeTour.pricing_for !== null ? props.activeTour.pricing_for.toString() : null,
        images: props.activeTour.images,
        itinery: props.activeTour.itinery,
        schedules: props.activeTour.schedules,
        locationgroups: props.activeTour.locationgroups,
        locationgroupsLKR: props.activeTour.locationgroupsLKR,
        sale_type: props.activeTour.sale_type !== null ? props.activeTour.sale_type.toString() : null,
        cutoff_time_duration: props.activeTour.cutoff_time_duration,
        cutoff_time_measure: props.activeTour.cutoff_time_measure,
        cutoff_time_related: props.activeTour.cutoff_time_related,
        commission: props.activeTour.commission,
        include_adults: props.activeTour.include_adults,
        include_children: props.activeTour.include_children,
        include_infants: props.activeTour.include_infants,
        cover_image: props.activeTour.cover_image,
        know_policy: props.activeTour.know_policy,
        cancel_policy: props.activeTour.cancel_policy,
        cancel_custom: props.activeTour.cancel_custom,
        terms_accepted: props.activeTour.terms_accepted,
        status: props.activeTour.status,
        local_inclustions: props.activeTour.local_inclustions,
        local_conditions: props.activeTour.local_conditions,
        destination_location: props.activeTour.destination_location,
      }
    }

    if (state.id === 0 && state.categories.length === 0) {

      var $cats = [];
      for (var x = 0; x < props.categories.length; x++) {
        $cats.push({ id: props.categories[x].id, checked: false, label: props.categories[x].name });

      }

      return {
        categories: $cats,
        updated: true
      }
    }

    return null;


  }


  handleCatChange = (e, index) => {


    const item = e.target.id;
    const isChecked = e.target.checked;



    this.setState({
      ...this.state,
      categories: Object.values({
        ...this.state.categories,
        [index]: {
          ...this.state.categories[index],
          checked: isChecked
        }
      })
    });


  }


  handleItChange = (event, name, index) => {
    this.setState({
      ...this.state,
      itinery: Object.values({
        ...this.state.itinery,
        [index]: {
          ...this.state.itinery[index],
          summery: event.target.value
        }
      })
    })
  }

  uploadImage = (data, fileName, type) => {
    this.props.uploadPicture(data, fileName, type, this.state.id);
  }

  getSteps() {
    return ['Introduction', 'Basics', 'Overview', 'Pricing', 'Terms & Conditions', 'Itinerary', 'Pictures', 'Contact Details', 'Local', 'Submit & Review'];
  }

  // Location Group Update START

  addLocationGroup = () => {
    var locationGroupsArray = new Array();
    locationGroupsArray = this.state.locationgroups;
    locationGroupsArray.push({
      id: 0,
      pickUpLocations: [],
      seasons: [
        {
          name: "New availability",
          from_date: new Date(),
          to_date: new Date(),
          adultPricing: [],
          childrenPricing: [],
          infantPricing: []
        }
      ],
    });




    this.setState({
      locationgroups: locationGroupsArray
    });

  }

  deleteLocationGroup = (GroupIndex) => {

    this.setState({
      ...this.state,
      locationgroups: [
        ...this.state.locationgroups.slice(0, GroupIndex),
        ...this.state.locationgroups.slice(GroupIndex + 1),
      ]
    }, () => console.log(this.state));

  }

  addGroupPickupLocation = (sIndex, location) => {

    this.setState({
      ...this.state,
      locationgroups: Object.values({
        ...this.state.locationgroups,
        [sIndex]: {
          ...this.state.locationgroups[sIndex],
          pickUpLocations: Object.values({
            ...this.state.locationgroups[sIndex].pickUpLocations,
            location
          })
        }
      })
    }, () => console.log(this.state));
  }

  updateGroupPickupLocation = (GroupIndex, LocationIndex, FieldName, value) => {



    this.setState({
      ...this.state,
      locationgroups: Object.values({
        ...this.state.locationgroups,
        [GroupIndex]: {
          ...this.state.locationgroups[GroupIndex],
          pickUpLocations: Object.values({
            ...this.state.locationgroups[GroupIndex].pickUpLocations,
            [LocationIndex]: {
              ...this.state.locationgroups[GroupIndex].pickUpLocations[LocationIndex],
              [FieldName]: value
            }
          })
        }
      })
    }, () => console.log(this.state));


  }

  deleteGroupPickupLocation = (GroupIndex, LocationIndex) => {
    this.setState({
      ...this.state,
      locationgroups: Object.values({
        ...this.state.locationgroups,
        [GroupIndex]: {
          ...this.state.locationgroups[GroupIndex],
          pickUpLocations: [
            ...this.state.locationgroups[GroupIndex].pickUpLocations.slice(0, LocationIndex),
            ...this.state.locationgroups[GroupIndex].pickUpLocations.slice(LocationIndex + 1),
          ]
        }
      })
    }, () => console.log(this.state));
  }

  addSeason = (GroupIndex) => {

    this.setState({
      ...this.state,
      locationgroups: Object.values({
        ...this.state.locationgroups,
        [GroupIndex]: {
          ...this.state.locationgroups[GroupIndex],
          seasons: [
            ...this.state.locationgroups[GroupIndex].seasons,
            {
              name: "New availability",
              from_date: new Date(),
              to_date: new Date(),
              adultPricing: [],
              childrenPricing: [],
              infantPricing: []
            }
          ]
        }
      })
    }, () => console.log(this.state));

  }

  deleteSeason = (GroupIndex, SeasonIndex) => {

    this.setState({
      ...this.state,
      locationgroups: Object.values({
        ...this.state.locationgroups,
        [GroupIndex]: {
          ...this.state.locationgroups[GroupIndex],
          seasons: [
            ...this.state.locationgroups[GroupIndex].seasons.slice(0, SeasonIndex),
            ...this.state.locationgroups[GroupIndex].seasons.slice(SeasonIndex + 1),
          ]
        }
      })
    }, () => console.log(this.state));

  }

  changeScheduleTitle = (value, GroupIndex, SeasonIndex) => {

    this.setState({
      ...this.state,
      locationgroups: Object.values({
        ...this.state.locationgroups,
        [GroupIndex]: {
          ...this.state.locationgroups[GroupIndex],
          seasons: Object.values({
            ...this.state.locationgroups[GroupIndex].seasons,
            [SeasonIndex]: {
              ...this.state.locationgroups[GroupIndex].seasons[SeasonIndex],
              name: value
            }
          })
        }
      })
    }, () => console.log(this.state));

  }

  changeDate = (value, field, GroupIndex, SeasonIndex) => {

    //if (!this.validateDate(value, GroupIndex,SeasonIndex)) {

    // } else {

    this.setState({
      ...this.state,
      locationgroups: Object.values({
        ...this.state.locationgroups,
        [GroupIndex]: {
          ...this.state.locationgroups[GroupIndex],
          seasons: Object.values({
            ...this.state.locationgroups[GroupIndex].seasons,
            [SeasonIndex]: {
              ...this.state.locationgroups[GroupIndex].seasons[SeasonIndex],
              [field]: value,
            }
          })
        }
      })
    }, () => console.log(this.state));


    //}



  }


  // Location Group Update END

  addSchedule = () => {
    var scheduleArray = new Array();
    scheduleArray = this.state.schedules;
    scheduleArray.push({
      id: 0,
      name: 'New availability',
      from_date: new Date(),
      to_date: new Date(),
      locations: [
        {
          pickUpLocations: [

          ],
          adultPricing: [

          ],
          childrenPricing: [

          ],
          infantPricing: [

          ]
        }
      ],
    });




    this.setState({
      schedules: scheduleArray
    });

  }

  addLocation = (sIndex) => {
    this.setState({
      ...this.state,
      schedules: Object.values({
        ...this.state.schedules,
        [sIndex]: {
          ...this.state.schedules[sIndex],
          locations: [
            ...this.state.schedules[sIndex].locations,
            {
              pickUpLocations: [

              ],
              adultPricing: [

              ],
              childrenPricing: [

              ],
              infantPricing: [

              ]
            }
          ]
        }
      })
    }, () => console.log(this.state));
  }

  removeSchedule = (sIndex) => {
    this.setState({
      ...this.state,
      schedules: [
        ...this.state.schedules.slice(0, sIndex),
        ...this.state.schedules.slice(sIndex + 1),
      ]
    }, () => console.log(this.state));
  }

  removeLocation = (sIndex, lIndex) => {
    this.setState({
      ...this.state,
      schedules: Object.values({
        ...this.state.schedules,
        [sIndex]: {
          ...this.state.schedules[sIndex],
          locations: [
            ...this.state.schedules[sIndex].locations.slice(0, lIndex),
            ...this.state.schedules[sIndex].locations.slice(lIndex + 1),
          ]
        }
      })
    }, () => console.log(this.state));
  }

  addPickupLocation = (sIndex, lIndex, location) => {
    console.log(sIndex);
    this.setState({
      ...this.state,
      schedules: Object.values({
        ...this.state.schedules,
        [sIndex]: {
          ...this.state.schedules[sIndex],
          locations: Object.values({
            ...this.state.schedules[sIndex].locations,
            [lIndex]: {
              ...this.state.schedules[sIndex].locations[lIndex],
              pickUpLocations: [...this.state.schedules[sIndex].locations[lIndex].pickUpLocations,
                location
              ],
            }
          })
        }
      })
    }, () => console.log(this.state));
  }

  updatePickupLocation = (sIndex, lIndex, pIndex, field, value) => {
    this.setState({
      ...this.state,
      schedules: Object.values({
        ...this.state.schedules,
        [sIndex]: {
          ...this.state.schedules[sIndex],
          locations: Object.values({
            ...this.state.schedules[sIndex].locations,
            [lIndex]: {
              ...this.state.schedules[sIndex].locations[lIndex],
              pickUpLocations: Object.values({
                ...this.state.schedules[sIndex].locations[lIndex].pickUpLocations,
                [pIndex]: {
                  ...this.state.schedules[sIndex].locations[lIndex].pickUpLocations[pIndex],
                  [field]: value
                }
              }),
            }
          })
        }
      })
    }, () => console.log(this.state));
  }





  addPricGroup = (GroupIndex, SeasonIndex, group, data) => {



    this.setState({
      ...this.state,
      locationgroups: Object.values({
        ...this.state.locationgroups,
        [GroupIndex]: {
          ...this.state.locationgroups[GroupIndex],
          seasons: Object.values({
            ...this.state.locationgroups[GroupIndex].seasons,
            [SeasonIndex]: {
              ...this.state.locationgroups[GroupIndex].seasons[SeasonIndex],
              [group]: [
                ...this.state.locationgroups[GroupIndex].seasons[SeasonIndex][group],
                data
              ],
            }
          })
        }
      })
    }, () => { this.updateNetPrice(GroupIndex, SeasonIndex, group, this.state.locationgroups[GroupIndex].seasons[SeasonIndex][group].length - 1) });
  }

  updatePricGroup = (sIndex, lIndex, group, gIndex, field, value) => {
    this.setState({
      ...this.state,
      locationgroups: Object.values({
        ...this.state.locationgroups,
        [sIndex]: {
          ...this.state.locationgroups[sIndex],
          seasons: Object.values({
            ...this.state.locationgroups[sIndex].seasons,
            [lIndex]: {
              ...this.state.locationgroups[sIndex].seasons[lIndex],
              [group]: Object.values({
                ...this.state.locationgroups[sIndex].seasons[lIndex][group],
                [gIndex]: {
                  ...this.state.locationgroups[sIndex].seasons[lIndex][group][gIndex],
                  [field]: value
                }
              }),
            }
          })
        }
      })
    }, () => { this.updateNetPrice(sIndex, lIndex, group, gIndex) });
  }

  updateNetPrice = (sIndex, lIndex, group, gIndex) => {
    this.setState({
      ...this.state,
      locationgroups: Object.values({
        ...this.state.locationgroups,
        [sIndex]: {
          ...this.state.locationgroups[sIndex],
          seasons: Object.values({
            ...this.state.locationgroups[sIndex].seasons,
            [lIndex]: {
              ...this.state.locationgroups[sIndex].seasons[lIndex],
              [group]: Object.values({
                ...this.state.locationgroups[sIndex].seasons[lIndex][group],
                [gIndex]: {
                  ...this.state.locationgroups[sIndex].seasons[lIndex][group][gIndex],
                  ['net_price']: this.state.locationgroups[sIndex].seasons[lIndex][group][gIndex]['selling_price'] - (this.state.locationgroups[sIndex].seasons[lIndex][group][gIndex]['selling_price'] * (this.state.commission / 100))
                }
              }),
            }
          })
        }
      })
    });
  }



  removePricGroup = (sIndex, lIndex, group, gIndex) => {
    this.setState({
      ...this.state,
      locationgroups: Object.values({
        ...this.state.locationgroups,
        [sIndex]: {
          ...this.state.locationgroups[sIndex],
          seasons: Object.values({
            ...this.state.locationgroups[sIndex].seasons,
            [lIndex]: {
              ...this.state.locationgroups[sIndex].seasons[lIndex],
              [group]: [
                ...this.state.locationgroups[sIndex].seasons[lIndex][group].slice(0, gIndex),
                ...this.state.locationgroups[sIndex].seasons[lIndex][group].slice(gIndex + 1),

              ],
            }
          })
        }
      })
    });
  }


  changeDateLocal = (value, field, GroupIndex, SeasonIndex) => {

    //if (!this.validateDate(value, GroupIndex,SeasonIndex)) {

    // } else {

    this.setState({
      ...this.state,
      locationgroupsLKR: Object.values({
        ...this.state.locationgroupsLKR,
        [GroupIndex]: {
          ...this.state.locationgroupsLKR[GroupIndex],
          seasons: Object.values({
            ...this.state.locationgroupsLKR[GroupIndex].seasons,
            [SeasonIndex]: {
              ...this.state.locationgroupsLKR[GroupIndex].seasons[SeasonIndex],
              [field]: value,
            }
          })
        }
      })
    }, () => console.log(this.state));


    //}



  }

  changeScheduleTitleLocal = (value, GroupIndex, SeasonIndex) => {

    this.setState({
      ...this.state,
      locationgroupsLKR: Object.values({
        ...this.state.locationgroupsLKR,
        [GroupIndex]: {
          ...this.state.locationgroupsLKR[GroupIndex],
          seasons: Object.values({
            ...this.state.locationgroupsLKR[GroupIndex].seasons,
            [SeasonIndex]: {
              ...this.state.locationgroupsLKR[GroupIndex].seasons[SeasonIndex],
              name: value
            }
          })
        }
      })
    }, () => console.log(this.state));

  }


  addLocationLocal = (sIndex) => {
    console.log("loc")
    this.setState({
      ...this.state,
      schedules: Object.values({
        ...this.state.schedules,
        [sIndex]: {
          ...this.state.schedules[sIndex],
          locations: [
            ...this.state.schedules[sIndex].locations,
            {
              pickUpLocations: [

              ],
              adultPricing: [

              ],
              childrenPricing: [

              ],
              infantPricing: [

              ]
            }
          ]
        }
      })
    }, () => console.log(this.state));
  }



  addScheduleLocal = () => {
    var scheduleArray = new Array();
    scheduleArray = this.state.schedules;
    scheduleArray.push({
      id: 0,
      name: 'New availability',
      from_date: new Date(),
      to_date: new Date(),
      locations: [
        {
          pickUpLocations: [

          ],
          adultPricing: [

          ],
          childrenPricing: [

          ],
          infantPricing: [

          ]
        }
      ],
    });




    this.setState({
      schedules: scheduleArray
    });

  }


  addPickupLocationLocal = (sIndex, lIndex, location) => {
    console.log(sIndex);
    this.setState({
      ...this.state,
      schedules: Object.values({
        ...this.state.schedules,
        [sIndex]: {
          ...this.state.schedules[sIndex],
          locations: Object.values({
            ...this.state.schedules[sIndex].locations,
            [lIndex]: {
              ...this.state.schedules[sIndex].locations[lIndex],
              pickUpLocations: [...this.state.schedules[sIndex].locations[lIndex].pickUpLocations,
                location
              ],
            }
          })
        }
      })
    }, () => console.log(this.state));
  }


  addPricGroupLocal = (GroupIndex, SeasonIndex, group, data) => {
    console.log(data)
    console.log(group)
    console.log(GroupIndex)
    console.log(SeasonIndex)
    this.setState({
      ...this.state,
      locationgroupsLKR: Object.values({
        ...this.state.locationgroupsLKR,
        [GroupIndex]: {
          ...this.state.locationgroupsLKR[GroupIndex],
          seasons: Object.values({
            ...this.state.locationgroupsLKR[GroupIndex].seasons,
            [SeasonIndex]: {
              ...this.state.locationgroupsLKR[GroupIndex].seasons[SeasonIndex],
              [group]: [
                ...this.state.locationgroupsLKR[GroupIndex].seasons[SeasonIndex][group],
                data
              ],
            }
          })
        }
      })
    }, () => { this.updateLocalNetPrice(GroupIndex, SeasonIndex, group, this.state.locationgroupsLKR[GroupIndex].seasons[SeasonIndex][group].length - 1) });
  }


  updatePricGroupLocal = (sIndex, lIndex, group, gIndex, field, value) => {
    this.setState({
      ...this.state,
      locationgroupsLKR: Object.values({
        ...this.state.locationgroupsLKR,
        [sIndex]: {
          ...this.state.locationgroupsLKR[sIndex],
          seasons: Object.values({
            ...this.state.locationgroupsLKR[sIndex].seasons,
            [lIndex]: {
              ...this.state.locationgroupsLKR[sIndex].seasons[lIndex],
              [group]: Object.values({
                ...this.state.locationgroupsLKR[sIndex].seasons[lIndex][group],
                [gIndex]: {
                  ...this.state.locationgroupsLKR[sIndex].seasons[lIndex][group][gIndex],
                  [field]: value
                }
              }),
            }
          })
        }
      })
    }, () => { this.updateLocalNetPrice(sIndex, lIndex, group, gIndex) });
  }

  removePricGroupLocal = (sIndex, lIndex, group, gIndex) => {
    console.log("removePricGroupLocal")
    console.log(sIndex)
    console.log(lIndex)
    console.log(group)
    console.log(sIndex)
    this.setState({
      ...this.state,
      locationgroupsLKR: Object.values({
        ...this.state.locationgroupsLKR,
        [sIndex]: {
          ...this.state.locationgroupsLKR[sIndex],
          seasons: Object.values({
            ...this.state.locationgroupsLKR[sIndex].seasons,
            [lIndex]: {
              ...this.state.locationgroupsLKR[sIndex].seasons[lIndex],
              [group]: [
                ...this.state.locationgroupsLKR[sIndex].seasons[lIndex][group].slice(0, gIndex),
                ...this.state.locationgroupsLKR[sIndex].seasons[lIndex][group].slice(gIndex + 1),

              ],
            }
          })
        }
      })
    });
  }


  removeLocationLocal = (sIndex, lIndex) => {
    this.setState({
      ...this.state,
      schedules: Object.values({
        ...this.state.schedules,
        [sIndex]: {
          ...this.state.schedules[sIndex],
          locations: [
            ...this.state.schedules[sIndex].locations.slice(0, lIndex),
            ...this.state.schedules[sIndex].locations.slice(lIndex + 1),
          ]
        }
      })
    }, () => console.log(this.state));
  }


  removeScheduleLocal = (sIndex) => {
    this.setState({
      ...this.state,
      schedules: [
        ...this.state.schedules.slice(0, sIndex),
        ...this.state.schedules.slice(sIndex + 1),
      ]
    }, () => console.log(this.state));
  }


  updatePickupLocationLocal = (sIndex, lIndex, pIndex, field, value) => {
    this.setState({
      ...this.state,
      schedules: Object.values({
        ...this.state.schedules,
        [sIndex]: {
          ...this.state.schedules[sIndex],
          locations: Object.values({
            ...this.state.schedules[sIndex].locations,
            [lIndex]: {
              ...this.state.schedules[sIndex].locations[lIndex],
              pickUpLocations: Object.values({
                ...this.state.schedules[sIndex].locations[lIndex].pickUpLocations,
                [pIndex]: {
                  ...this.state.schedules[sIndex].locations[lIndex].pickUpLocations[pIndex],
                  [field]: value
                }
              }),
            }
          })
        }
      })
    }, () => console.log(this.state));
  }


  addLocationGroupLocal = () => {
    var locationGroupsArray = new Array();
    locationGroupsArray = this.state.locationgroupsLKR;
    locationGroupsArray.push({
      id: 0,
      pickUpLocations: [],
      seasons: [
        {
          id: 0,
          name: "New availability",
          from_date: new Date(),
          to_date: new Date(),
          adultPricing: [],
          childrenPricing: [],
          infantPricing: []
        }
      ],
    });




    this.setState({
      locationgroupsLKR: locationGroupsArray
    });

  }


  deleteLocationGroupLocal = (GroupIndex) => {

    this.setState({
      ...this.state,
      locationgroupsLKR: [
        ...this.state.locationgroupsLKR.slice(0, GroupIndex),
        ...this.state.locationgroupsLKR.slice(GroupIndex + 1),
      ]
    }, () => console.log(this.state));

  }


  addGroupPickupLocationLocal = (sIndex, location) => {
    console.log(sIndex)
    console.log(location)

    this.setState({
      ...this.state,
      locationgroupsLKR: Object.values({
        ...this.state.locationgroupsLKR,
        [sIndex]: {
          ...this.state.locationgroupsLKR[sIndex],
          pickUpLocations: Object.values({
            ...this.state.locationgroupsLKR[sIndex].pickUpLocations,
            location
          })
        }
      })
    }, () => console.log(this.state));
  }


  addSeasonLocal = (GroupIndex) => {

    this.setState({
      ...this.state,
      locationgroupsLKR: Object.values({
        ...this.state.locationgroupsLKR,
        [GroupIndex]: {
          ...this.state.locationgroupsLKR[GroupIndex],
          seasons: [
            ...this.state.locationgroupsLKR[GroupIndex].seasons,
            {
              id: 0,
              name: "New availability",
              from_date: new Date(),
              to_date: new Date(),
              adultPricing: [],
              childrenPricing: [],
              infantPricing: []
            }
          ]
        }
      })
    }, () => console.log(this.state));

  }


  deleteSeasonLocal = (GroupIndex, SeasonIndex) => {

    this.setState({
      ...this.state,
      locationgroupsLKR: Object.values({
        ...this.state.locationgroupsLKR,
        [GroupIndex]: {
          ...this.state.locationgroupsLKR[GroupIndex],
          seasons: [
            ...this.state.locationgroupsLKR[GroupIndex].seasons.slice(0, SeasonIndex),
            ...this.state.locationgroupsLKR[GroupIndex].seasons.slice(SeasonIndex + 1),
          ]
        }
      })
    }, () => console.log(this.state));

  }


  deleteGroupPickupLocationLocal = (GroupIndex, LocationIndex) => {

    this.setState({
      ...this.state,
      locationgroupsLKR: Object.values({
        ...this.state.locationgroupsLKR,
        [GroupIndex]: {
          ...this.state.locationgroupsLKR[GroupIndex],
          pickUpLocations: [
            ...this.state.locationgroupsLKR[GroupIndex].pickUpLocations.slice(0, LocationIndex),
            ...this.state.locationgroupsLKR[GroupIndex].pickUpLocations.slice(LocationIndex + 1),
          ]
        }
      })
    }, () => console.log(this.state));
  }


  updateGroupPickupLocationLocal = (GroupIndex, LocationIndex, FieldName, value) => {



    this.setState({
      ...this.state,
      locationgroupsLKR: Object.values({
        ...this.state.locationgroupsLKR,
        [GroupIndex]: {
          ...this.state.locationgroupsLKR[GroupIndex],
          pickUpLocations: Object.values({
            ...this.state.locationgroupsLKR[GroupIndex].pickUpLocations,
            [LocationIndex]: {
              ...this.state.locationgroupsLKR[GroupIndex].pickUpLocations[LocationIndex],
              [FieldName]: value
            }
          })
        }
      })
    }, () => console.log(this.state));


  }


  validateDate = (date, GroupIndex, SeasonIndex) => {

    for (var x = 0; x < this.state.locationgroups[GroupIndex].seasons.length; x = x + 1) {

      if (date > this.state.schedules[x].from_date && date < this.state.schedules[x].to_date) {

        if (x !== SeasonIndex) {
          return false;
        }

      }
    }

    return true;
  }

  matchSettings = () => {

    var durationValue = this.state.duration_value;

    if (this.state.tour_type.toString() === '1') {


      if (this.state.duration_value < 2) {
        durationValue = 2;
      }

      if (this.state.duration_value > 30) {
        durationValue = 30;
      }

      this.setState({
        duration: 1,
        duration_value: durationValue
      });
    }

    if (this.state.tour_type.toString() === '2') {
      var duration = this.state.duration;


      if (duration === 1) {
        duration = 2;
      }

      if (duration === 0) {
        duration = 2;
      }

      if (duration === 2) {

        if (this.state.duration_value > 24) {
          durationValue = 24;
        }

        if (this.state.duration_value < 1) {
          durationValue = 1;

        }
      }

      this.setState({
        duration: duration,
        duration_value: durationValue
      });
    }

    this.setState({
      title: this.state.title !== null ? this.state.title.slice(0, 170) : null,
      overview: this.state.overview !== null ? this.state.overview.slice(0, 600) : null
    });
  }

  getTripType(type) {
    switch (type) {
      case 1: { return "Round tour" }
      case 2: { return "Day Tour" }
      case 3: { return "Activity" }
    }
  }

  getDuration(measure) {
    switch (measure) {
      case 1: { return "Days" }
      case 2: { return "Hours" }
      case 3: { return "Minutes" }
    }
  }


  getBookingType(type) {
    switch (type) {
      case 1: { return "Instant" }
      case 2: { return "On availability" }
    }
  }


  getPricingType(type) {
    switch (type) {
      case 1: { return "Per Person" }
      case 2: { return "Per Group" }
    }
  }


  getCutOffBase(type) {
    switch (type) {
      case 1: { return "to the starting date" }
      case 2: { return "to the starting time" }
    }
  }



  handleBasicsEdit = () => {
    this.setState({
      basicEditMode: true,
    })
  }

  validateBasics = (silent) => {

    if (this.state.title.length === 0) {

      if (silent === false) {
        this.props.SnackBar(true, 'Tour title can not be empty', 'error');
      }

      return false;
    }


    if (this.state.tour_type === 0) {
      if (silent === false) {
        this.props.SnackBar(true, 'Please select tour type', 'error');
      }

      return false;
    }



    var numCats = 0;

    for (var x = 0; x < this.state.categories.length; x++) {
      if (this.state.categories[x].checked === true) {
        numCats++;
      }
    }

    if (numCats === 0) {
      if (silent === false) {
        this.props.SnackBar(true, 'Please select catogories', 'error');
      }

      return false;
    }

    this.setState({
      basicEditMode: false,
      step2Complete: true
    })

    return true;
  }

  handleOverviewEdit = () => {
    this.setState({
      overviewEditMode: true,
    })
  }

  validateOverview = (silent) => {
    if (this.state.overview.length < 400) {
      if (silent === false) {
        this.props.SnackBar(true, 'Overview must be minimum 400 characters', 'error');
      }

      return false;
    }

    this.setState({
      overviewEditMode: false
    })

    return true;

  }


  handlePricingDefinewEdit = () => {
    this.setState({
      pricingDefineEditMode: true,
    })
  }


  validatePricingDefine = (silent) => {


    if (this.state.sale_type === null) {

      if (silent === false) {
        this.props.SnackBar(true, 'Please select availability Type', 'error');
      }

      return false;
    }


    if (this.state.pricing_for === null) {
      if (silent === false) {
        this.props.SnackBar(true, 'Please select pricing type', 'error');
      }
      return false;
    }

    if (this.state.cutoff_time_related === 0) {
      if (silent === false) {
        this.props.SnackBar(true, 'Invalid cut-off time settings', 'error');
      }
      return false;
    }


    if (this.state.cutoff_time_measure === 0) {
      if (silent === false) {
        this.props.SnackBar(true, 'Invalid cut-off time settings', 'error');
      }
      return false;
    }


    if (parseInt(this.state.cutoff_time_duration) === 0) {
      if (silent === false) {
        this.props.SnackBar(true, 'Invalid cut-off time settings', 'error');
      }
      return false;
    }


    this.setState({
      pricingDefineEditMode: false,
      pricingValidated: true
    })


    return true;

  }


  validatePaxDefine = (silent) => {


    if (parseInt(this.state.include_adults) === 0 && parseInt(this.state.include_children) === 0 && parseInt(this.state.include_infants) === 0) {
      if (silent === false) {
        this.props.SnackBar(true, 'Please select PAX age groups', 'error');
      }

      return false;
    }


    if (parseInt(this.state.include_adults) === 1) {

      if ((this.state.adults_age_from) === null) {
        if (silent === false) {
          this.props.SnackBar(true, 'Invalid PAX age range', 'error');
        }
        return false;
      }

      if ((this.state.adults_age_to) === null) {
        if (silent === false) {
          this.props.SnackBar(true, 'Invalid PAX age range', 'error');
        }
        return false;
      }

      if (parseInt(this.state.adults_age_to) < parseInt(this.state.adults_age_from)) {
        if (silent === false) {
          this.props.SnackBar(true, 'Invalid PAX age range', 'error');
        }
        return false;
      }

    }

    if (parseInt(this.state.include_children) === 1) {

      if ((this.state.children_age_from) === null) {
        if (silent === false) {
          this.props.SnackBar(true, 'Invalid PAX age range', 'error');
        }
        return false;
      }

      if ((this.state.children_age_to) === null) {
        if (silent === false) {
          this.props.SnackBar(true, 'Invalid PAX age range', 'error');
        }
        return false;
      }

      if (parseInt(this.state.children_age_to) < parseInt(this.state.children_age_from)) {
        if (silent === false) {
          this.props.SnackBar(true, 'Invalid PAX age range', 'error');
        }
        return false;
      }

    }

    if (parseInt(this.state.include_infants) === 1) {

      if ((this.state.infant_age_from) === null) {
        if (silent === false) {
          this.props.SnackBar(true, 'Invalid PAX age range', 'error');
        }
        return false;
      }

      if ((this.state.infant_age_to) === null) {
        if (silent === false) {
          this.props.SnackBar(true, 'Invalid PAX age range', 'error');
        }
        return false;
      }

      if (parseInt(this.state.infant_age_to) < parseInt(this.state.infant_age_from)) {
        if (silent === false) {
          this.props.SnackBar(true, 'Invalid PAX age range', 'error');
        }
        return false;
      }

      if (parseInt(this.state.include_children) === 1) {

        if ((parseInt(this.state.infant_age_from) >= parseInt(this.state.children_age_from)) && (parseInt(this.state.infant_age_from) <= parseInt(this.state.children_age_to))) {
          if (silent === false) {
            this.props.SnackBar(true, 'Child-Infant age mismatch', 'error');
          }
          return false;
        }

        if ((parseInt(this.state.infant_age_to) >= parseInt(this.state.children_age_from)) && (parseInt(this.state.infant_age_to) <= parseInt(this.state.children_age_to))) {
          if (silent === false) {
            this.props.SnackBar(true, 'Child-Infant age mismatch', 'error');
          }
          return false;
        }

      }

    }


    return true;

  }



  validateAvailablity = (silent) => {

    if (this.state.locationgroups.length === 0) {
      if (silent === false) {
        this.props.SnackBar(true, 'Invalid availablity schedule', 'error');
      }
      return false;
    }



    if (this.state.locationgroups[0].seasons.length === 0) {
      if (silent === false) {
        this.props.SnackBar(true, 'Invalid availablity locations', 'error');
      }
      return false;
    }

    if (this.state.locationgroups[0].pickUpLocations.length === 0) {
      if (silent === false) {
        this.props.SnackBar(true, 'Invalid pickup locations setting', 'error');
      }
      return false;
    }

    if (this.state.locationgroups[0].seasons[0].adultPricing.length === 0) {
      if (silent === false) {
        this.props.SnackBar(true, 'Invalid availablity pricing setting', 'error');
      }
      return false;
    }

    return true;
  }

  validateTC = (silent) => {
    if (this.state.inclusion.length === 0) {
      if (silent === false) {
        this.props.SnackBar(true, 'Invalid inclusions ', 'error');
      }
      return false;
    }

    if (this.state.exclision.length === 0) {
      if (silent === false) {
        this.props.SnackBar(true, 'Invalid exclutions ', 'error');
      }
      return false;
    }

    if (this.state.know_policy.length === 0) {
      if (silent === false) {
        this.props.SnackBar(true, 'Invalid Know before you go ', 'error');
      }
      return false;
    }

    if (this.state.condtions.length === 0) {
      if (silent === false) {
        this.props.SnackBar(true, 'Invalid Know before you book', 'error');
      }
      return false;
    }

    if (this.state.cancel_policy === null || this.state.cancel_policy.length === 0) {
      console.log(this.state);
      if (silent === false) {
        this.props.SnackBar(true, 'Invalid Cancelation Policy', 'error');
      }
      return false;
    }

    if (this.state.terms_accepted === 0) {
      if (silent === false) {
        this.props.SnackBar(true, 'You need to accept terms and conditions', 'error');
      }
      return false;
    }

    return true;
  }

  vlidateItinery = (silent) => {

    for (var x = 0; x < this.state.itinery.length; x++) {
      if (this.state.itinery[x].summery === null) {
        if (silent === false) {
          this.props.SnackBar(true, 'Invalid Itinerary', 'error');
        }
        return false;
      }
    }

    return true;
  }

  vlidateImages = (silent) => {

    if (this.state.cover_image === null) {
      if (silent === false) {
        this.props.SnackBar(true, 'Invalid cover image', 'error');
      }
      return false;
    }

    if (this.state.images.length < 3) {
      if (silent === false) {
        this.props.SnackBar(true, 'Minimum 3 images required', 'error');
      }
      return false;
    }

    return true;
  }

  vlidateContactNumber = (silent) => {

    if (this.state.contact_number === null) {
      if (silent === false) {
        this.props.SnackBar(true, 'Invalid contact number', 'error');
      }
      return false;
    }


    return true;
  }

  searchPolicy(key, policyArray) {

    for (var i = 0; i < policyArray.length; i++) {

      if (policyArray[i].id.toString() === key) {
        return policyArray[i];
      }
    }

    return { content: "" };
  }

  handlePolicyChange = (e, field) => {

    if (field === 'cancel_policy') {
      let policy = this.searchPolicy(e, this.props.cancel_policies);

      this.setState({
        cancel_policy: policy.content
      })
    }


  }


  handleTermsAccepted = (e) => {
    this.setState({
      terms_accepted: e.target.checked === true ? 1 : 0,
    })
  }

  handleImageType = (type) => {
    this.setState({ image_type: type })
  }

  getStepContent(step) {
    const { classes } = this.props;
    switch (step) {

      case 0:
        return <div>
          <div id="supplier-main-block">
            <h2>Welcome</h2>
            <div className="supplier-block introduction-details">
              <a href="#" className="edit-block"></a>
              <div className="block-content">
                <div className="inner-block-content">
                  <div className="one-full-col ">
                    <div className="supply-col">
                      <div className="col-variable">
                        <div className="intro-steps">
                          <div className="step">
                            <div className="step-label">Step 1</div>
                            <div className="step-icon"><img src={Step1Icon} /></div>
                            <div className="step-desc">
                              Follow the steps and feed the tour details correctly
                            </div>
                          </div>

                          <div className="step">
                            <div className="step-label">Step 2</div>
                            <div className="step-icon"><img src={Step2Icon} /></div>
                            <div className="step-desc">
                              Include your phone number and finish the product
                            </div>
                          </div>
                          <div className="step">
                            <div className="step-label">Step 3</div>
                            <div className="step-icon"><img src={Step3Icon} /></div>
                            <div className="step-desc">
                              Submit your product for review
                            </div>
                          </div>
                          <div className="step">
                            <div className="step-label">Step 4</div>
                            <div className="step-icon"><img src={Step4Icon} /></div>
                            <div className="step-desc">
                              Admin will review and make adjustments
                            </div>
                          </div>
                          <div className="step">
                            <div className="step-label">Step 5</div>
                            <div className="step-icon"><img src={Step5Icon} /></div>
                            <div className="step-desc">
                              Product is available to purchase
                            </div>
                          </div>

                          <div className="clear"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="clear"></div>
                </div>
              </div>

            </div>
          </div>
        </div>;

      case 1:
        return (
          <Fragment>

            <div  >
              <div id="supplier-main-block">
                <h2>Basics</h2>

                {this.state.basicEditMode === false ? (<div className="supplier-block basics-details">
                  <a href="#" onClick={() => this.handleBasicsEdit()} className="edit-block"></a>
                  <div className="block-content">
                    <div className="inner-block-content">
                      <div className="one-two-col ">
                        <div className="supply-col">
                          <label>Tour Title</label>
                          <div className="col-variable">{this.state.title}</div>
                        </div>
                        <div className="clear"></div>

                        <div className="supply-col">
                          <br />
                          <label>Categories</label>
                          <div className="col-variable">
                            {this.state.categories.map((cat, index) => {

                              return cat.checked === true ? <Chip style={{ margin: 2 }} label={cat.label} /> : null;

                            })}
                          </div>
                        </div>
                      </div>

                      <div className="one-two-col ">
                        <div className="supply-col details-summary">
                          <div className="one-third-col">
                            <label>Tour Type</label>
                            <div className="col-variable">{this.getTripType(parseInt(this.state.tour_type))}</div>
                          </div>

                          <div className="one-third-col ">
                            <label>Duration</label>
                            <div className="col-variable">{this.state.duration_value}</div>
                          </div>

                          <div className="one-third-col ">
                            <label>Days/Hrs/Mins</label>
                            <div className="col-variable">{this.getDuration(parseInt(this.state.duration))}</div>
                          </div>



                        </div>
                      </div>


                      <div className="clear"></div>

                    </div>
                  </div>

                </div>) : (<div className="supplier-block basics-details-editable editable">
                  <a href="#" className="close-block"></a>
                  <div className="block-content">
                    <div className="inner-block-content">
                      <div className="one-two-col ">
                        <div className="supply-col">
                          <label>Basics</label>
                          <div className="col-variable">
                            <FormControl style={{ width: '77%' }}>
                              <TextField
                                native
                                autoComplete='off'
                                id="outlined-name"
                                label={'Tour Title'}
                                className={classes.textField}
                                value={this.state.title}
                                onChange={this.handleChange('title')}
                                placeholder="Title type here"
                                margin="normal"
                                variant="outlined"

                              />
                              <div style={{ textAlign: 'right' }}>
                                <small>{'(' + this.state.title.length + '/170)'}</small>
                              </div>


                            </FormControl>

                          </div>
                        </div>
                      </div>

                      <div className="one-two-col ">
                        <div className="supply-col details-summary">
                          <label>&nbsp;</label>
                          <div className="col-variable"><div className="col-variable">
                            <FormControl style={{ minWidth: 200, marginTop: 16 }} variant="outlined" className={classes.formControl}>
                              <InputLabel
                                ref={ref => {
                                  this.InputLabelRef = ref;
                                }}
                                htmlFor="outlined-age-native-simple"
                              >
                                Tour Type
                              </InputLabel>
                              <Select
                                native
                                value={this.state.tour_type}
                                onChange={this.handleChange('tour_type')}

                                input={
                                  <OutlinedInput
                                    name="Tour Type"
                                    labelWidth={70}
                                    id="outlined-age-native-simple"
                                  />
                                }
                              >
                                <option value={0} />
                                <option value={1}>Round Tour</option>
                                <option value={2}>Day Tour</option>
                                {/* <option value={3}>Activity</option> */}
                              </Select>
                            </FormControl>
                            <FormControl variant="outlined" style={{ marginTop: 16 }} className={classes.formControl}>
                              <InputLabel
                                ref={ref => {
                                  this.InputLabelRef = ref;
                                }}
                                htmlFor="outlined-age-native-simple"
                              >
                                Duration
                              </InputLabel>
                              <Select
                                native
                                value={this.state.duration}
                                onChange={this.handleChange('duration')}
                                input={
                                  <OutlinedInput
                                    name="Duration"
                                    labelWidth={70}
                                    id="outlined-age-native-simple"
                                  />
                                }
                              >
                                <option value="" />
                                {this.state.tour_type.toString() === '1' ? (<option value={1}>Days</option>) : null}
                                {this.state.tour_type.toString() === '2' || this.state.tour_type.toString() === '3' ? (<option value={2}>Hours</option>) : null}
                                {this.state.tour_type.toString() === '2' || this.state.tour_type.toString() === '3' ? (<option value={3}>Minutes</option>) : null}


                              </Select>
                            </FormControl>

                            <FormControl style={{ width: 100 }}>
                              <TextField
                                autoComplete='off'
                                id="outlined-name"
                                label="Days/Hrs/Min"
                                className={classes.textField}
                                value={this.state.duration_value}
                                onChange={this.handleChange('duration_value')}
                                placeholder="Days/Hrs/Min type here"
                                margin="normal"
                                variant="outlined"
                                type="number"
                              />
                            </FormControl>
                          </div></div>
                        </div>

                        <div className="supply-col details-summary">
                          <label>&nbsp;</label>
                          <div className="col-variable"><div className="col-variable">
                            <FormControl style={{ minWidth: 200, marginTop: 16 }} variant="outlined" className={classes.formControl}>
                              <InputLabel
                                ref={ref => {
                                  this.InputLabelRef = ref;
                                }}
                                htmlFor="outlined-age-native-simple"
                              >
                                Destination
                              </InputLabel>
                              <Select
                                native
                                value={this.state.destination_location}

                                onChange={this.handleChange('destination_location')}

                                input={
                                  <OutlinedInput
                                    name="Destination"
                                    labelWidth={70}
                                    id="outlined-age-native-simple"
                                  />
                                }
                              >
                                <option value={0} />
                                {this.props.locations.map((item, index) => {
                                  return <option key={'loc' + index.toString()} value={item.value}>{item.label}</option>
                                })}


                                {/* <option value={3}>Activity</option> */}
                              </Select>
                            </FormControl>


                          </div>
                          </div>
                        </div>

                      </div>


                      <div className="clear"></div>
                    </div>


                    <div className="inner-block-content">
                      <div className="one-full-col ">
                        <div className="supply-col tour-category">
                          <label>Category</label>
                          <div className="col-variable">
                            <div className="tour-category-list">
                              {this.state.categories.map((cat, index) => {
                                return <FormControlLabel

                                  control={
                                    <Checkbox name={cat.name} id={cat.id} color="primary" checked={cat.checked} onChange={(e) => { this.handleCatChange(e, index) }}
                                    />
                                  }
                                  label={cat.label} />
                              })}

                            </div>
                            <div className="clear"></div>
                          </div>
                        </div>
                      </div>



                      <div className="clear"></div>
                      {/* <div style={{ textAlign: 'right' }} >
                      <div class="button-container">
                      <a   class="button-apply" onClick={()=>this.validateBasics(true)}>Done</a>
                      </div>

                      </div> */}
                    </div>
                  </div>

                </div>)}







              </div>
            </div>

            <Divider />
          </Fragment>);

      case 2:
        return (<div>
          <div>
            <div id="supplier-main-block">
              <h2>Overview</h2>

              {this.state.overviewEditMode === false ? (<div class="supplier-block overview-details">
                <a onClick={() => this.handleOverviewEdit()} class="edit-block"></a>
                <div class="block-content">
                  <div class="inner-block-content">
                    <div class="one-full-col ">
                      <div class="supply-col">

                        <div class="col-variable">
                          <div class="inner-description">
                            {this.state.overview}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="clear"></div>
                  </div>
                </div>
              </div>) : (<div class="supplier-block overview-details editable">
                <a href="#" class="close-block"></a>
                <div class="block-content">
                  <div class="inner-block-content">
                    <div class="one-full-col ">
                      <div class="supply-col">

                        <div class="col-variable">
                          <div class="inner-description">
                            <TextField
                              id="outlined-multiline-static"
                              placeholder="Describe your tour..."
                              multiline
                              rows="6"
                              value={this.state.overview}
                              onChange={this.handleChange('overview')}
                              className={classes.textField}
                              margin="normal"
                              variant="outlined"
                            />
                            <div style={{ textAlign: 'right' }}>
                              <small>({this.state.overview.length}/600)</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="clear"></div>
                    <br />
                    {/* <div class="button-container">
                      <a   class="button-apply" onClick={()=>this.validateOverview(false)}>Done a</a>
                      </div> */}
                  </div>
                </div>

              </div>
              )}


            </div>
          </div>



          <br />
          <Divider />
        </div>);

      case 3:
        return (<div>

          <div id="supplier-main-block">
            <h2>Pricing</h2>


            {this.state.pricingDefineEditMode === false ? (<div className="supplier-block sales-details">
              <a onClick={() => this.handlePricingDefinewEdit()} className="edit-block"></a>
              <div className="block-title">Sales Details</div>
              <div className="block-content">

                <div className="one-third-col sales-type">
                  <div className="supply-col">
                    <label>Availability Type</label>
                    <div className="col-variable">{this.getBookingType(parseInt(this.state.sale_type))}</div>
                  </div>

                  <div className="supply-col">
                    <label>Pricing Type</label>
                    <div className="col-variable">{this.getPricingType(parseInt(this.state.pricing_for))}</div>
                  </div>
                </div>

                <div className="one-third-col">
                  <div className="supply-col">
                    <label>Cut-off Time</label>
                    <div className="col-variable">{this.state.cutoff_time_duration} {this.getDuration(parseInt(this.state.cutoff_time_measure))} {this.getCutOffBase(parseInt(this.state.cutoff_time_related))} </div>

                  </div>
                </div>


                <div className="clear"></div>
              </div>
            </div>

            ) : (<div className="supplier-block sales-details-editable editable">
              <div className="block-title">Sales Details</div>
              <div className="block-content">
                <div className="block-inner-content">
                  <div className="one-two-col">
                    <div className="supply-col editable-sales-col">
                      <label>Availability Type</label>
                      <div className="col-variable">
                        <FormControl component="fieldset" className={classes.formControl}>
                          <RadioGroup
                            aria-label="Gender"
                            name="gender1"
                            className={classes.group}
                            value={this.state.sale_type}
                            onChange={this.handleChange('sale_type')}
                          >
                            <FormControlLabel value={"1"} control={<Radio color="primary" style={{ color: 'orange' }} />} label="Instant" />
                            <FormControlLabel value={"2"} control={<Radio color="primary" style={{ color: 'orange' }} />} label="On availability" />

                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>

                    <div className="supply-col">
                      <label>Pricing Type</label>
                      <div className="col-variable">
                        <FormControl component="fieldset" className={classes.formControl}>
                          <RadioGroup
                            aria-label="Gender"
                            name="gender1"
                            className={classes.group}
                            value={this.state.pricing_for}
                            onChange={this.handleChange('pricing_for')}
                          >
                            <FormControlLabel value={"1"} control={<Radio color="primary" style={{ color: 'orange' }} />} label="Per Person" />
                            <FormControlLabel value={"2"} control={<Radio color="primary" style={{ color: 'orange' }} />} label="Per Group" />

                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                  </div>

                  <div className="one-two-col">
                    <div className="supply-col">
                      <label>Cut-off Time</label>
                      <FormControl variant="outlined" style={{ marginTop: 16 }} className={classes.formControl}>
                        <InputLabel
                          ref={ref => {
                            this.InputLabelRef = ref;
                          }}
                          htmlFor="outlined-age-native-simple"
                        >
                          Related
                        </InputLabel>
                        <Select
                          native
                          value={this.state.cutoff_time_related}
                          onChange={this.handleChange('cutoff_time_related')}
                          input={
                            <OutlinedInput
                              name="related"
                              labelWidth={70}
                              id="outlined-age-native-simple"
                            />
                          }
                        >
                          <option value="" />
                          <option value={1}>to the starting date</option>
                          <option value={2}> to the starting time</option>

                        </Select>
                      </FormControl>
                      <FormControl variant="outlined" style={{ marginTop: 16 }} className={classes.formControl}>
                        <InputLabel
                          ref={ref => {
                            this.InputLabelRef = ref;
                          }}
                          htmlFor="outlined-age-native-simple"
                        >
                          Duration
                        </InputLabel>
                        <Select
                          native
                          value={this.state.cutoff_time_measure}
                          onChange={this.handleChange('cutoff_time_measure')}
                          input={
                            <OutlinedInput
                              name="Duration"
                              labelWidth={70}
                              id="outlined-age-native-simple"
                            />
                          }
                        >
                          <option value="" />
                          <option value={1}>Days</option>
                          <option value={2}>Hours</option>
                          <option value={3}>Minutes</option>
                        </Select>
                      </FormControl>

                      <FormControl style={{ width: 100 }}>
                        <TextField
                          autoComplete='off'
                          id="outlined-name"
                          label="Days/Hrs/Min"
                          className={classes.textField}
                          value={this.state.cutoff_time_duration}
                          onChange={this.handleChange('cutoff_time_duration')}
                          placeholder="Days/Hrs/Min type here"
                          margin="normal"
                          variant="outlined"
                          type="number"
                        />
                      </FormControl>
                    </div>
                  </div>
                  <div className="clear"></div>
                </div>


                <br />
                <div className="button-container" >
                  <a onClick={() => this.validatePricingDefine(false)} className="button-apply">Done</a>
                </div>
              </div>
            </div>

            )}

            {this.state.pricingValidated === true ? (<PaxDefindBox
              handleChecked={this.handleChecked}
              handleChange={this.handleChange}
              handleChangeValue={this.handleChangeValue}
              includeAdults={this.state.include_adults}
              adultsAgeFrom={this.state.adults_age_from}
              adultsAgeTo={this.state.adults_age_to}
              includeChildren={this.state.include_children}
              childrenAgeFrom={this.state.children_age_from}
              childrenAgeTo={this.state.children_age_to}
              includeInfants={this.state.include_infants}
              infantAgeFrom={this.state.infant_age_from}
              infantAgeTo={this.state.infant_age_to}
              validateData={this.validatePaxDefine}
            />) : null}


          </div>

          {this.validatePaxDefine(true) === true ? (<SchedulePanel
            changeDate={this.changeDate}
            changeDateLocal={this.changeDateLocal}

            changeScheduleTitleLocal={this.changeScheduleTitleLocal}
            changeScheduleTitle={this.changeScheduleTitle}

            addLocation={this.addLocation}
            addLocationLocal={this.addLocationLocal}

            addScheduleLocal={this.addScheduleLocal}
            addSchedule={this.addSchedule}

            addPickup={this.addPickupLocation}
            addPickupLocationLocal={this.addPickupLocationLocal}

            addPricGroupLocal={this.addPricGroupLocal}
            addPriceGroup={this.addPricGroup}

            updatePricGroupLocal={this.updatePricGroupLocal}
            updatePricGroup={this.updatePricGroup}

            removePricGroup={this.removePricGroup}
            removePricGroupLocal={this.removePricGroupLocal}

            removeLocationLocal={this.removeLocationLocal}
            removeLocation={this.removeLocation}

            removeSchedule={this.removeSchedule}
            removeScheduleLocal={this.removeScheduleLocal}
            activity={this.state}
            shedules={this.state.schedules}
            editMode={this.state.schedules.length === 1 && this.state.schedules[0].id === 0}
            updatePickupLocation={this.updatePickupLocation}
            updatePickupLocationLocal={this.updatePickupLocationLocal}

            locationgroups={this.state.locationgroups}
            locationgroupsLKR={this.state.locationgroupsLKR}

            addLocationGroupLocal={this.addLocationGroupLocal}
            addLocationGroup={this.addLocationGroup}

            deleteLocationGroup={this.deleteLocationGroup}
            deleteLocationGroupLocal={this.deleteLocationGroupLocal}

            addGroupPickupLocationLocal={this.addGroupPickupLocationLocal}
            addGroupPickupLocation={this.addGroupPickupLocation}

            addSeasonLocal={this.addSeasonLocal}
            addSeason={this.addSeason}

            deleteSeasonLocal={this.deleteSeasonLocal}
            deleteSeason={this.deleteSeason}

            deleteGroupPickupLocationLocal={this.deleteGroupPickupLocationLocal}
            deleteGroupPickupLocation={this.deleteGroupPickupLocation}

            updateGroupPickupLocation={this.updateGroupPickupLocation}
            updateGroupPickupLocationLocal={this.updateGroupPickupLocationLocal}
          ></SchedulePanel>) : null}

          <br />
          <Divider />
        </div>);

      case 4:
        return (<div>



          <div id="supplier-main-block">
            <h2>Terms & Conditions</h2>

            <OptionsBox label="Inclusions" showDone={false} value={this.state.inclusion} required={true} onError={this.props.SnackBar} onChange={(val) => this.commitChange('inclusion', val)} />

            <OptionsBox label="Exclusions" showDone={false} value={this.state.exclision} required={true} onError={this.props.SnackBar} onChange={(val) => this.commitChange('exclision', val)} />

            <OptionsBox label="Know before you go" showDone={false} value={this.state.know_policy} templates={this.props.know_policies} required={true} onError={this.props.SnackBar} onChange={(val) => this.commitChange('know_policy', val)} />

            <OptionsBox label="Know before you book" showDone={false} value={this.state.condtions} required={true} onError={this.props.SnackBar} onChange={(val) => this.commitChange('condtions', val)} />

            <TermsBpx label="Cancelation Policy" hideTextArea={true} showDone={false} value={this.state.cancel_custom} required={false} templates={this.props.cancel_policies} seleted={this.state.cancel_policy} policyChange={(e) => this.handlePolicyChange(e, 'cancel_policy')} onError={this.props.SnackBar} onChange={this.handleChange('cancel_custom')} />

            <div>

              <FormControlLabel style={{ paddingLeft: 10, marginBottom: 10 }}
                control={
                  <Checkbox
                    checked={this.state.terms_accepted === 1}
                    onChange={this.handleTermsAccepted}
                    value="checkedB"
                    color="primary"
                    style={{ color: 'orange' }}
                  />
                }
                label="Accept"
              />

            </div>
          </div>

          <br />
          <Divider />
        </div>);

      case 5:
        return (<div>


          <div id="supplier-main-block">
            <h2>Itinerary</h2>

            {this.state.itinery.map((it, index) => (

              <TermsBpx label={it.label} value={it.summery} showDone={false} required={true} onError={this.props.SnackBar} onChange={(e) => this.handleItChange(e, 'summery', index)} />

            ))}

          </div>



          <br />
          <Divider />
        </div>);

      case 6:
        return (<div>
          <div id="supplier-main-block">
            <h2>Product Images</h2>
            <ImageBox label="Cover Image" handleImageType={this.handleImageType} image_type={this.state.image_type} ratio={4 / 4} info="" type="cover" image={this.state.cover_image} onChange={this.uploadImage} />
            <ImageBox label="Other Images" handleImageType={this.handleImageType} image_type={this.state.image_type} ratio={16 / 9} info="Max Photos-8, Min Photos-3" type="other" images={this.state.images} onRemove={this.props.RemoveImage} onChange={this.uploadImage} />
          </div>
          <br />
          <Divider />
        </div>)

      case 7:
        return (<div>

          <div id="supplier-main-block">
            <h2>Contact Details</h2>
            <TermsBpx label={"Contact Number"} showDone={false} value={this.state.contact_number} required={true} onError={this.props.SnackBar} onChange={this.handleChange('contact_number')} />



          </div>

          <Divider />
        </div>);

      case 8:
        return (
          <div>
            <OptionsBox showDone={true} label="Inclutions" value={this.state.local_inclustions} required={true} onError={this.props.SnackBar} onChange={(val) => this.commitChange('local_inclustions', val)} maxChars={400} />

            <TermsBpx label="Conditions" hideTextArea={false} value={this.state.local_conditions} required={false} onError={this.props.SnackBar} onChange={this.handleChange('local_conditions')} />

            <SchedulePanel market="LOCAl" changeDate={this.changeDate}
              changeDateLocal={this.changeDateLocal}

              changeScheduleTitleLocal={this.changeScheduleTitleLocal}
              changeScheduleTitle={this.changeScheduleTitle}

              addLocation={this.addLocation}
              addLocationLocal={this.addLocationLocal}

              addScheduleLocal={this.addScheduleLocal}
              addSchedule={this.addSchedule}

              addPickup={this.addPickupLocation}
              addPickupLocationLocal={this.addPickupLocationLocal}

              addPricGroupLocal={this.addPricGroupLocal}
              addPriceGroup={this.addPricGroup}

              updatePricGroupLocal={this.updatePricGroupLocal}
              updatePricGroup={this.updatePricGroup}

              removePricGroup={this.removePricGroup}
              removePricGroupLocal={this.removePricGroupLocal}

              removeLocationLocal={this.removeLocationLocal}
              removeLocation={this.removeLocation}

              removeSchedule={this.removeSchedule}
              removeScheduleLocal={this.removeScheduleLocal}
              activity={this.state}
              shedules={this.state.schedules}
              editMode={this.state.schedules.length === 1 && this.state.schedules[0].id === 0}
              updatePickupLocation={this.updatePickupLocation}
              updatePickupLocationLocal={this.updatePickupLocationLocal}

              locationgroups={this.state.locationgroups}
              locationgroupsLKR={this.state.locationgroupsLKR}

              addLocationGroupLocal={this.addLocationGroupLocal}
              addLocationGroup={this.addLocationGroup}

              deleteLocationGroup={this.deleteLocationGroup}
              deleteLocationGroupLocal={this.deleteLocationGroupLocal}

              addGroupPickupLocationLocal={this.addGroupPickupLocationLocal}
              addGroupPickupLocation={this.addGroupPickupLocation}

              addSeasonLocal={this.addSeasonLocal}
              addSeason={this.addSeason}

              deleteSeasonLocal={this.deleteSeasonLocal}
              deleteSeason={this.deleteSeason}

              deleteGroupPickupLocationLocal={this.deleteGroupPickupLocationLocal}
              deleteGroupPickupLocation={this.deleteGroupPickupLocation}

              updateGroupPickupLocation={this.updateGroupPickupLocation}
              updateGroupPickupLocationLocal={this.updateGroupPickupLocationLocal}
            ></SchedulePanel>


          </div>
        );

      default:
        return (
          <div>
            <ActivityStatus
              showDone={false}
              full_seo_url={this.state.full_seo_url}
              status={this.state.status}
              onSubmit={() => this.handleSubmitForReview()} />
          </div>
        );
    }
  }


  handleNext = () => {
    this.setState(state => ({
      activeStep: state.activeStep + 1,
    }));
  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1,
    }));
  };

  handleReset = () => {
    this.setState({
      activeStep: 1,
    });
  };

  componentDidMount() {
    document.body.style.background = "#eee";
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    }, () => { this.matchSettings(); });
  };

  handleChangeValue = (name , value) => {
    this.setState({
      [name]: value,
    }, () => { this.matchSettings(); });
  };

  commitChange = (name, value) => {
    this.setState({
      [name]: value,
    }, () => { this.matchSettings(); });
  };


  handleChecked  = (event, name) => {
    this.setState({
      [name]: event.target.checked === true ? 1 : 0,
    }, () => { this.matchSettings(); });
  };




  totalSteps = () => this.getSteps().length;

  isStepOptional = step => step === 1;

  handleSkip = () => {
    const { activeStep } = this.state;
    if (!this.isStepOptional(activeStep)) {
      // You probably want to guard against something like this
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    this.setState(state => {
      const skipped = new Set(state.skipped.values());
      skipped.add(activeStep);
      return {
        activeStep: state.activeStep + 1,
        skipped,
      };
    });
  };




  handleNext = () => {
    let activeStep = this.state.activeStep;
    if (!this.validateBasics(true)) {
      this.setState({
        basicEditMode: true
      })
    }

    if (!this.validateOverview(true)) {
      this.setState({
        overviewEditMode: true
      })
    }

    if (!this.validatePricingDefine(true)) {
      this.setState({
        pricingDefineEditMode: true
      })
    }


    if (activeStep === 1) {
      if (!this.validateBasics(false)) {
        return false
      }
    }


    if (activeStep === 2) {
      if (!this.validateOverview(false)) {
        return false
      }
    }


    if (activeStep === 3) {
      if (!this.validatePricingDefine(false)) {
        return false
      }

      if (!this.validatePaxDefine(false)) {
        return false;
      }

      if (!this.validateAvailablity(false)) {
        return false;
      }

    }


    if (activeStep === 4) {


      if (!this.validateTC(false)) {

        return false
      }
    }

    if (activeStep === 5) {

      if (!this.vlidateItinery(false)) {

        return false
      }
    }

    if (activeStep === 6) {

      if (!this.vlidateImages(false)) {

        return false
      }
    }

    if (activeStep === 7) {

      if (!this.vlidateContactNumber(false)) {

        return false
      }
    }


    if (this.isLastStep() && !this.allStepsCompleted()) {
      // It's the last step, but not all steps have been completed
      // find the first step that has been completed
      const steps = this.getSteps();
      activeStep = steps.findIndex((step, i) => !this.state.completed.has(i));
      this.props.updateActivity(this.state);
    } else {
      activeStep = this.state.activeStep + 1;
    }

    if (activeStep > 1) {

      if (activeStep === 1) {
        if (!this.validateBasics(false)) {
          return false
        }
      }


      this.props.updateActivity(this.state);
    }



    this.setState({
      activeStep,
    });
  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1,
    }));
  };

  handleSubmitForReview = () => {
    this.props.ReviewSubmit(this.state);
  }

  handleStep = step => () => {

    let activeStep = this.state.activeStep;

    if (step === 1) {

      if (!this.validateBasics(true)) {
        this.setState({
          basicEditMode: true
        });


      }
    }

    if (step === 2) {

      if (!this.validateBasics(true)) {
        this.setState({
          basicEditMode: true
        });

        return false;
      }
    }

    if (step === 3) {

      if (!this.validateBasics(true)) {
        this.setState({
          basicEditMode: true
        })

        return false;
      }

      if (!this.validateOverview(true)) {
        this.setState({
          overviewEditMode: true
        })

        return false;
      }
    }

    if (step === 4) {

      if (!this.validateBasics(true)) {
        this.setState({
          basicEditMode: true
        })

        return false;
      }

      if (!this.validateOverview(true)) {
        this.setState({
          overviewEditMode: true
        })

        return false;
      }

      if (!this.validatePricingDefine(true)) {
        this.setState({
          pricingDefineEditMode: true
        });

        return false;
      }

      if (!this.validatePaxDefine(true)) {
        this.setState({
          pricingDefineEditMode: true
        });

        return false;
      }

      if (!this.validateAvailablity(true)) {
        return false
      }
    }

    if (step === 5) {

      if (!this.validateBasics(true)) {
        this.setState({
          basicEditMode: true
        })

        return false;
      }

      if (!this.validateOverview(true)) {
        this.setState({
          overviewEditMode: true
        })

        return false;
      }

      if (!this.validatePricingDefine(true)) {
        this.setState({
          pricingDefineEditMode: true
        });

        return false;
      }

      if (!this.validatePaxDefine(true)) {
        this.setState({
          pricingDefineEditMode: true
        });

        return false;
      }

      if (!this.validateAvailablity(true)) {
        return false
      }

      if (!this.validateTC(true)) {
        return false
      }
    }

    if (step === 6) {

      if (!this.validateBasics(true)) {
        this.setState({
          basicEditMode: true
        })

        return false;
      }

      if (!this.validateOverview(true)) {
        this.setState({
          overviewEditMode: true
        })

        return false;
      }

      if (!this.validatePricingDefine(true)) {
        this.setState({
          pricingDefineEditMode: true
        });

        return false;
      }

      if (!this.validatePaxDefine(true)) {
        this.setState({
          pricingDefineEditMode: true
        });

        return false;
      }

      if (!this.validateAvailablity(true)) {
        return false
      }

      if (!this.validateTC(true)) {
        return false
      }

      if (!this.vlidateItinery(true)) {
        return false
      }
    }

    if (step === 7) {

      if (!this.validateBasics(true)) {
        this.setState({
          basicEditMode: true
        })

        return false;
      }

      if (!this.validateOverview(true)) {
        this.setState({
          overviewEditMode: true
        })

        return false;
      }

      if (!this.validatePricingDefine(true)) {
        this.setState({
          pricingDefineEditMode: true
        });

        return false;
      }

      if (!this.validatePaxDefine(true)) {
        this.setState({
          pricingDefineEditMode: true
        });

        return false;
      }

      if (!this.validateAvailablity(true)) {
        return false
      }

      if (!this.validateTC(true)) {
        return false
      }

      if (!this.vlidateItinery(true)) {
        return false
      }

      if (!this.vlidateImages(true)) {
        return false
      }
    }

    if (step === 8) {

      if (!this.validateBasics(true)) {
        this.setState({
          basicEditMode: true
        })

        return false;
      }

      if (!this.validateOverview(true)) {
        this.setState({
          overviewEditMode: true
        })

        return false;
      }

      if (!this.validatePricingDefine(true)) {
        this.setState({
          pricingDefineEditMode: true
        });

        return false;
      }

      if (!this.validatePaxDefine(true)) {
        this.setState({
          pricingDefineEditMode: true
        });

        return false;
      }

      if (!this.validateAvailablity(true)) {
        return false
      }

      if (!this.validateTC(true)) {
        return false
      }

      if (!this.vlidateItinery(true)) {
        return false
      }

      if (!this.vlidateImages(true)) {
        return false
      }

      if (!this.vlidateContactNumber(true)) {
        return false
      }
    }

    //if(step > a


    this.setState({
      activeStep: step,
    });

  };

  handleComplete = () => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const completed = new Set(this.state.completed);
    completed.add(this.state.activeStep);
    this.setState({
      completed,
    });

    /**
     * Sigh... it would be much nicer to replace the following if conditional with
     * `if (!this.allStepsComplete())` however state is not set when we do this,
     * thus we have to resort to not being very DRY.
     */
    if (completed.size !== this.totalSteps() - this.skippedSteps()) {
      this.handleNext();
    }
  };

  handleReset = () => {
    this.setState({
      activeStep: 1,
      completed: new Set(),
      skipped: new Set(),
    });
  };

  skippedSteps() {
    return this.state.skipped.size;
  }

  isStepSkipped(step) {
    return this.state.skipped.has(step);
  }

  isStepComplete(step) {
    return this.state.completed.has(step);
  }

  completedSteps() {
    return this.state.completed.size;
  }

  allStepsCompleted() {
    return this.completedSteps() === this.totalSteps() - this.skippedSteps();
  }

  isLastStep() {
    return this.state.activeStep === this.totalSteps() - 1;
  }


  validateSteps = () => {

    if (this.state.activeStep === 0) {
      return false;
    }


    if (this.state.activeStep === 1) {
      this.validateBasics(false);
    }

    return true;
  }


  render() {
    const { classes } = this.props;
    const steps = this.getSteps();
    const { activeStep } = this.state;




    return <Fragment>
      <AppBar title="Dashboard"></AppBar>
      <Drawer currentPage="tours"></Drawer>

      <div style={{ width: 'auto', marginLeft: drawerWidth + 0, paddingTop: 35, height: 250 }}><div>

        <Table>

          <TableBody>
            <TableRow>
              <TableCell style={{ backgroundColor: '#fff', width: 500, fontWeight: 600, fontSize: 16 }}>&nbsp;{this.state.title}
                <Button onClick={() => { this.props.showInfoBox(true) }} variant="contained" style={{ background: "rgba(0, 0, 0, 0.54)", float: 'right' }} color="primary" className={classes.button}>
                  <b> Help</b>
                </Button>

              </TableCell>

            </TableRow>

          </TableBody>
        </Table>

        <Table>
          <TableBody>
            <TableRow>

              <TableCell style={{ backgroundColor: '#f2f2f2', width: 700, fontWeight: 500, paddingTop: 25 }} component="th">



                <Paper>
                  <div>
                    <div className={classes.root}>
                      <Stepper alternativeLabel nonLinear activeStep={activeStep}>
                        {steps.map((label, index) => {
                          const props = {};
                          const buttonProps = {};
                          if (this.isStepOptional(index)) {
                            buttonProps.optional = <Typography variant="caption"></Typography>;
                          }
                          if (this.isStepSkipped(index)) {
                            props.completed = false;
                          }
                          return (
                            <Step key={label} {...props}>
                              <StepButton

                                onClick={this.handleStep(index)}
                                completed={this.isStepComplete(index)}
                                {...buttonProps}
                              >
                                <StepLabel StepIconProps={{ classes: { root: classes.icon } }} >
                                  {label}
                                </StepLabel>

                              </StepButton>
                            </Step>
                          );
                        })}
                      </Stepper>
                      <div>
                        {this.allStepsCompleted() ? (
                          <div>
                            <Typography className={classes.instructions}>
                              All steps completed - you&apos;re finished
                            </Typography>
                            <Button onClick={this.handleReset} >Reset</Button>
                          </div>
                        ) : (
                          <div>
                            <Typography className={classes.instructions}>{this.getStepContent(activeStep)}</Typography>
                            <div>
                              <Button
                                disabled={activeStep === 0}
                                onClick={this.handleBack}
                                className={classes.button}
                              >
                                Back
                              </Button>

                              {this.state.activeStep === this.totalSteps() - 1 ? null : (<Button
                                variant="contained"
                                color="primary"
                                onClick={this.handleNext}
                                className={classes.button}
                                style={{ background: "#f7a414" }}>

                                {this.state.activeStep === this.totalSteps() - 1 ? "Finish" : "Next"}
                              </Button>)}




                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>


                </Paper>

              </TableCell>

            </TableRow>

          </TableBody>
        </Table>
      </div>
      </div>

    </Fragment>
  }
}

HorizontalNonLinearAlternativeLabelStepper.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(HorizontalNonLinearAlternativeLabelStepper);
