import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';

class PaxDefineBox extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            paxDefineEditMode: !this.props.validateData(true),
        }
    }


    handleEditMode = () => {
        this.setState({
            paxDefineEditMode: true
        });
    }


    handleApply = () => {

        if(this.props.validateData(false)){
            this.setState({
                paxDefineEditMode: false
            });
        }
        
    }

    handleChecked= (event,name) => { 
        switch (name) {
            case "include_adults":
                this.props.handleChecked(event,'include_adults')
                break;
        
            case "include_children":
                this.props.handleChecked(event,'include_children')
                break;
        
            case "include_infants":
                this.props.handleChecked(event,'include_infants')
                break;
        
            default:
                break;
        }
        if(!event.target.checked) {
            
            switch (name) {
                case "include_adults":
                    this.props.handleChangeValue('adults_age_from', "")
                    this.props.handleChangeValue('adults_age_to', "")
                    break;
            
                case "include_children":
                    this.props.handleChangeValue('children_age_from', "")
                    this.props.handleChangeValue('children_age_to', "")
                    break;
            
                case "include_infants":
                    this.props.handleChangeValue('infant_age_from', "")
                    this.props.handleChangeValue('infant_age_to', "")
                    break;
            
                default:
                    break;
            }
        }
    }

    render() {
        return <div>
            <h2>PAX Age Define</h2>
            {this.state.paxDefineEditMode === false ? (<div className="supplier-block sales-details">
                <a onClick={() => this.handleEditMode()} className="edit-block"></a>
                <div className="block-title">Age Define Details</div>
                <div className="block-content">


                    <div className="one-third-col pax-define">
                        <div className="supply-col">
                            
                            {parseInt(this.props.includeAdults) === 1 ? (<div className="pax-block">
                                <div className="col-variable">{this.props.adultsAgeFrom}-{this.props.adultsAgeTo} </div>
                                <div className="below-label">Adults</div>
                            </div>) : null}

                            {parseInt(this.props.includeChildren) === 1 ? (
                                <div className="pax-block">
                                    <div className="col-variable">{this.props.childrenAgeFrom}-{this.props.childrenAgeTo} </div>
                                    <div className="below-label">Child</div>
                                </div>
                            ) : null}

                            {parseInt(this.props.includeInfants) === 1 ? (
                                <div className="pax-block">
                                    <div className="col-variable">{this.props.infantAgeFrom}-{this.props.infantAgeTo} </div>
                                    <div className="below-label">Infant</div>
                                </div>
                            ) : null}


                            <div className="clear"></div>
                        </div>
                    </div>
                    <div className="clear"></div>
                </div>
            </div>

            ) : (<div className="supplier-block sales-details-editable editable">
                <div className="block-title">PAX Age Define</div>
                <div className="block-content">
                    <div className="block-inner-content">
                        <div className="clear"></div>
                    </div>

                    <div className="block-inner-content">
                        <div className="one-one-col">
                            <div className="supply-col pax-define-block" style={{ border: 'none' }}>

                                <div className="pax-age-define">
                                    <div className="pax-age-col" style={{ width: '33.33%' }}>
                                        <div className="pax-label">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={this.props.includeAdults === 1}
                                                        onChange={(e)=>this.handleChecked(e,'include_adults')}
                                                        value="checkedB"
                                                        color="primary"
                                                        style={{color:'orange'}} 
                                                    />
                                                }
                                                label="Adults"
                                            />
                                        </div>
                                        <div className="pax-select-box">
                                            <FormControl>

                                                <InputLabel htmlFor="age-native-simple">From</InputLabel>
                                                <Select
                                                    native
                                                    value={this.props.adultsAgeFrom}
                                                    onChange={this.props.handleChange('adults_age_from')}
                                                    variant="outlined"
                                                    disabled={this.props.includeAdults !== 1}
                                                    inputProps={{
                                                        name: 'ageAdults',
                                                        id: 'age-native-simple',
                                                    }}
                                                >
                                                    <option value="" />
                                                    <option value={12}>12 years</option>
                                                    <option value={13}>13 years</option>
                                                    <option value={14}>14 years</option>
                                                    <option value={15}>15 years</option>
                                                    <option value={16}>16 years</option>
                                                    <option value={17}>17 years</option>
                                                    <option value={18}>18 years</option>
                                                    <option value={19}>19 years</option>
                                                    <option value={20}>20 years</option>
                                                    <option value={25}>25 years</option>
                                                    <option value={30}>30 years</option>
                                                    <option value={35}>35 years</option>
                                                    <option value={40}>40 years</option>
                                                    <option value={45}>45 years</option>
                                                    <option value={50}>50 years</option>
                                                    <option value={55}>55 years</option>
                                                    <option value={60}>60 years</option>
                                                    <option value={65}>65 years</option>
                                                    <option value={70}>70 years</option>
                                                    <option value={75}>75 years</option>
                                                    <option value={80}>80 years</option>
                                                    <option value={85}>85 years</option>
                                                    <option value={90}>90 years</option>
                                                    <option value={95}>95 years</option>
                                                    <option value={99}>99 years</option>
                                                </Select>
                                            </FormControl>
                                            <FormControl>
                                                <InputLabel htmlFor="age-native-simple">To</InputLabel>
                                                <Select
                                                    native
                                                    value={this.props.adultsAgeTo}
                                                    onChange={this.props.handleChange('adults_age_to')}
                                                    disabled={this.props.includeAdults !== 1}
                                                    inputProps={{
                                                        name: 'ageAdults2',
                                                        id: 'age-native-simple',
                                                    }}
                                                >
                                                    <option value="" />
                                                    <option value={12}>12 years</option>
                                                    <option value={13}>13 years</option>
                                                    <option value={14}>14 years</option>
                                                    <option value={15}>15 years</option>
                                                    <option value={16}>16 years</option>
                                                    <option value={17}>17 years</option>
                                                    <option value={18}>18 years</option>
                                                    <option value={19}>19 years</option>
                                                    <option value={20}>20 years</option>
                                                    <option value={25}>25 years</option>
                                                    <option value={30}>30 years</option>
                                                    <option value={35}>35 years</option>
                                                    <option value={40}>40 years</option>
                                                    <option value={45}>45 years</option>
                                                    <option value={50}>50 years</option>
                                                    <option value={55}>55 years</option>
                                                    <option value={60}>60 years</option>
                                                    <option value={65}>65 years</option>
                                                    <option value={70}>70 years</option>
                                                    <option value={75}>75 years</option>
                                                    <option value={80}>80 years</option>
                                                    <option value={85}>85 years</option>
                                                    <option value={90}>90 years</option>
                                                    <option value={95}>95 years</option>
                                                    <option value={99}>99 years</option>
                                                </Select>
                                            </FormControl>


                                            <div className="clear"></div>
                                        </div>
                                    </div>
                                </div>

                                <div className="pax-age-define">
                                    <div className="pax-age-col" style={{ width: '33.33%' }}>
                                        <div className="pax-label">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={this.props.includeChildren === 1}
                                                        onChange={(e)=>this.handleChecked(e, 'include_children')}
                                                        value="checkedB"
                                                        color="primary"
                                                        style={{color:'orange'}} 
                                                    />
                                                }
                                                label="Children"
                                            />
                                        </div>
                                        <div className="pax-select-box">
                                            <FormControl>
                                                <InputLabel htmlFor="age-native-simple">From</InputLabel>
                                                <Select
                                                    native
                                                    disabled={this.props.includeChildren !== 1}
                                                    value={this.props.childrenAgeFrom}
                                                    onChange={this.props.handleChange('children_age_from')}
                                                    inputProps={{
                                                        name: 'ageChildren',
                                                        id: 'age-native-simple',
                                                    }}
                                                >

                                                    <option value="" />
                                                    <option value={3}>3 years</option>
                                                    <option value={4}>4 years</option>
                                                    <option value={5}>5 years</option>
                                                    <option value={6}>6 years</option>
                                                    <option value={7}>7 years</option>
                                                    <option value={8}>8 years</option>
                                                    <option value={9}>9 years</option>
                                                    <option value={10}>10 years</option>
                                                    <option value={11}>11 years</option>
                                                </Select>
                                            </FormControl>

                                            <FormControl>
                                                <InputLabel htmlFor="age-native-simple">To</InputLabel>
                                                <Select
                                                    native
                                                    disabled={this.props.includeChildren !== 1}
                                                    value={this.props.childrenAgeTo}
                                                    onChange={this.props.handleChange('children_age_to')}
                                                    inputProps={{
                                                        name: 'ageChildren2',
                                                        id: 'age-native-simple',
                                                    }}
                                                >
                                                    <option value="" />
                                                    <option value={3}>3 years</option>
                                                    <option value={4}>4 years</option>
                                                    <option value={5}>5 years</option>
                                                    <option value={6}>6 years</option>
                                                    <option value={7}>7 years</option>
                                                    <option value={8}>8 years</option>
                                                    <option value={9}>9 years</option>
                                                    <option value={10}>10 years</option>
                                                    <option value={11}>11 years</option>
                                                </Select>
                                            </FormControl>
                                            <div className="clear"></div>
                                        </div>
                                    </div>
                                </div>



                                <div className="pax-age-define">
                                    <div className="pax-age-col" style={{ width: '33.33%' }}>
                                        <div className="pax-label">
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={this.props.includeInfants === 1}
                                                        onChange={(e)=>this.handleChecked(e, 'include_infants')}
                                                        value="checkedB"
                                                        color="primary"
                                                        style={{color:'orange'}}  
                                                    />
                                                }
                                                label="Infants"
                                            />
                                        </div>
                                        <div className="pax-select-box">
                                            <FormControl>
                                                <InputLabel htmlFor="age-native-simple">From</InputLabel>
                                                <Select
                                                    native
                                                    disabled={this.props.includeInfants !== 1}
                                                    value={this.props.infantAgeFrom}
                                                    onChange={this.props.handleChange('infant_age_from')}
                                                    inputProps={{
                                                        name: 'ageInfant',
                                                        id: 'age-native-simple',
                                                    }}
                                                >
                                                    <option value="" />
                                                    <option value={0}>0 month</option>
                                                    <option value={1}>1 year</option>
                                                    <option value={2}>2 years</option>
                                                    <option value={3}>3 years</option>
                                                    <option value={4}>4 years</option>
                                                    <option value={5}>5 years</option>
                                                </Select>
                                            </FormControl>

                                            <FormControl>
                                                <InputLabel htmlFor="age-native-simple">To</InputLabel>
                                                <Select
                                                    native
                                                    disabled={this.props.includeInfants !== 1}
                                                    value={this.props.infantAgeTo}
                                                    onChange={this.props.handleChange('infant_age_to')}
                                                    inputProps={{
                                                        name: 'ageInfant2',
                                                        id: 'age-native-simple',
                                                    }}
                                                >
                                                    <option value="" />
                                                    <option value={0}>0 month</option>
                                                    <option value={1}>1 year</option>
                                                    <option value={2}>2 years</option>
                                                    <option value={3}>3 years</option>
                                                    <option value={4}>4 years</option>
                                                    <option value={5}>5 years</option>
                                                </Select>
                                            </FormControl>
                                            <div className="clear"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="clear"></div>
                    </div>
                    <br />
                    <div className="button-container">
                        <a onClick={() => this.handleApply()} className="button-apply">Done</a>
                    </div>
                </div>
            </div>

                )}
        </div>
    }
}



export default PaxDefineBox;